<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import axios from 'axios'
export default {
  name: "App",

  metaInfo: {
    title: "主頁",
    titleTemplate: "Setup | %s",
    htmlAttrs: { lang: "en" },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import "./styles/main.scss";
</style>
