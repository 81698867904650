<template>
  <v-chip class="mr-1" color="grey1" dark small><slot /></v-chip>
</template>
<script>
export default {
  name: "ChipGrey",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss"></style>
