<template>
  <!-- 匯入資料 -->
  <material-dialog
    v-model="open"
    :title="errors.length == 0 ? '匯入資料' : '匯入資料失敗'"
    max-width="450"
  >
    <div v-if="errors.length == 0">
      <div class="importArea" @click="importData">
        <v-icon color="fubonBlue" size="30">mdi-file-upload-outline</v-icon>
        <v-btn color="fubonBlue" text>選取檔案</v-btn>
      </div>
      <div class="d-flex align-baseline mt-4 justify-space-between">
        {{ exampleFile.text }}範例檔
        <!-- TODO: Download Example File -->
        <v-btn color="primary" text class="text-decoration-underline"
          >點我下載</v-btn
        >
      </div>
      <input
        type="file"
        ref="uploadFile"
        class="d-none"
        truncate-length="15"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </div>

    <div v-else>
      <alert-card>
        <p>匯入失敗的可能原因或是情境列於此處，以便提醒user應該要如何修改。</p>
        <!-- test error -->
        <p v-for="err in errors" :key="err">{{ err }}</p>
      </alert-card>
    </div>

    <template #actions>
      <v-row v-if="errors.length == 0" class="ma-1">
        <v-spacer></v-spacer>
        <btn-cancel class="mr-2" @click="$emit('close')">取消</btn-cancel>
        <v-btn @click="$emit('submit')" depressed color="primary">匯入</v-btn>
      </v-row>
      <v-row v-else class="ma-1">
        <v-spacer></v-spacer>
        <btn-cancel class="mr-2" @click="$emit('close')">我知道了</btn-cancel>
        <v-btn @click="$emit('reset')" depressed color="primary"
          >重新匯入</v-btn
        >
      </v-row>
    </template>
  </material-dialog>
</template>
<script>
import AlertCard from "../views/auditField/components/AlertCard.vue";
export default {
  name: "DialogImport",
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  components: { AlertCard },
  computed: {
    exampleFile() {
      let metaData = this.meta.find((el) => el.type === this.type);
      // console.log({ metaData });
      if (metaData) return metaData;
      else return { text: "", example: "" };
    },
  },
  data() {
    return {
      meta: [
        { type: "unitField", text: "單位領域", example: "abc01.xls" },
        { type: "riskEvaluation", text: "風險評估", example: "abc02.xls" },
        { type: "auditPlan", text: "稽核計畫", example: "abc03.xls" },
      ],
    };
  },
  methods: {
    importData() {
      this.$refs.uploadFile.click();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.importArea {
  @include flexCenter;
  @include boxSize(100%, 120px);
  border: 2px dashed #cacaca;
  transition: all 0.2s ease;
  &:hover {
    background-color: rgba(#000, 0.05);
  }
}
</style>
