<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="material-input mb-3"
    :class="parseAttrs('noBorder') ? 'noBorder' : ''"
    single-line
    hide-details
  >
    <template #prepend>
      <div class="text-no-wrap mr-2">{{ $attrs.prependTitle }}</div>
    </template>
    <template v-for="slot in slots" #[slot]>
      <slot :name="slot" />
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "MaterialInput",
  data() {
    return {
      slots: ["append", "append-item", "append-outer", "label"],
    };
  },
  mounted() {
    // console.log(!!this.$attrs.noBorder);
  },
  methods: {
    parseAttrs(attr) {
      // console.log({ attr });
      // console.log(Object.keys(this.$attrs).includes(attr));
      return Object.keys(this.$attrs).includes(attr);
    },
  },
};
</script>
//
<style scoped lang="scss">
.material-input {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
  }
  &:deep(.v-input__prepend-outer) {
    color: #222;
    div {
      min-width: 65px;
    }
  }
  &.noBorder {
    &:deep(fieldset) {
      border-color: #fff !important;
    }
  }
}
</style>
