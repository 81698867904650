// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  alert: {
    open: false,
    title: "",
    content: ""
  }
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
