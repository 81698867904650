<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    class="material-select mb-3"
    hide-details
    single-line
    dense
    :menu-props="{ offsetY: true }"
  >
    <template v-if="parseAttrs('prependTitle')" #prepend>
      <div class="text-no-wrap mr-2">
        {{ $attrs.prependTitle }}
      </div>
    </template>
    <!-- <template v-for="slot in slots" #[slot]="{ item }">
      <slot :name="slot" :item="item" />
    </template> -->
    <template v-for="slot in slots" #[slot]>
      <slot :name="slot" />
    </template>
    <template v-if="parseAttrs('selection')" #selection="{ item, index }">
      <slot name="selection" :item="item" :index="index" />
    </template>
  </v-select>
</template>
<script>
export default {
  name: "MaterialSelect",
  data() {
    return {
      slots: ["append", "append-item", "append-outer", "label", "prepend-item"],
    };
  },
  mounted() {
    // console.log(this.$attrs);
  },
  methods: {
    parseAttrs(attr) {
      // console.log({ attr });
      // console.log(Object.keys(this.$attrs).includes(attr));
      return Object.keys(this.$attrs).includes(attr);
    },
  },
};
</script>
<style scoped lang="scss">
.material-select {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
    background: #fff;
  }
  &:deep(.v-input__prepend-outer) {
    color: #222;
    div {
      min-width: 65px;
    }
  }
}
</style>
