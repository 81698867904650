<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" persistent>
    <v-card class="pa-2">
      <v-card-title class="px-4 text-h3 font-weight-bold"
        >{{ $attrs.title }}
      </v-card-title>

      <v-divider v-show="parseAttrs('topDivider')"></v-divider>

      <v-card-text class="px-4 pt-1 pb-0 material-dialog-content">
        <slot />
      </v-card-text>

      <v-divider v-show="parseAttrs('bottomDivider')"></v-divider>

      <v-card-actions>
        <slot name="actions"> </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "MaterialDialog",
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    parseAttrs(attr) {
      return Object.keys(this.$attrs).includes(attr);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.material-dialog-content {
  min-height: 100px;
  color: $grey1 !important;
}
</style>
