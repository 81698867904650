import moment from "moment";

export const dateFormateMixin = {
  methods: {
    dateFormate: function (date) {
      if ([undefined, null, ""].includes(date)) {
        return "-";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
  },
}

export const versionMixin = {
  methods: {
    versionText: function (version) {
      if ([undefined, null, ''].includes(version)) {
        return ''
      }
      if (version.length < 2) {
        return "0" + version
      }
      return version
    },
    getThisYear: function () {
      return new Date().getFullYear()
    },
    getNextVersion: function (list) {
      let nextNo = list.reduce((acc, cur) => {
        if (acc < +cur.no) acc = +cur.no;
        return acc;
      }, 0) + 1
      console.log({ nextNo });
      return nextNo.toString();
    },
    getNewVersionData(rawVersionList) {
      let thisYearVersionList = rawVersionList.filter((el) => {
        let thisYear = this.getThisYear();
        return el.year == thisYear.toString();
      });
      console.log({ thisYearVersionList });
      let newVersionData = {
        year: this.getThisYear(),
        no: this.getNextVersion(thisYearVersionList),
      };
      return newVersionData
    },
  }
}

export const searchMixin = {
  methods: {
    combineQueries: function (pageMeta, filterData) {
      // console.log( {pageMeta});
      let searchData = {
        page: pageMeta.page,
        size: pageMeta.itemsPerPage,
        sortBy: pageMeta.options.sortBy[0] || null,
        order: pageMeta.options.sortDesc[0] ? "desc" : "asc",
      };
      if (filterData != null) {
        searchData = { ...searchData, ...filterData }
      }
      return searchData;
    },
  },
}


export const attrsMixin = {
  methods: {
    parseAttrs(attr) {
      return Object.keys(this.$attrs).includes(attr);
    },
  }
}
// 轉換小數點後兩位
export const FPMixin = {
  methods: {
    FPTwoPlacesTrans(str) {
      if (isNaN(parseFloat(str))) {
        return "-"
      }
      return parseFloat(str).toFixed(2);
    },
  }
}
// 浮點數算法
export const floatCalculatorMixin = {
  methods: {
    // 浮點數加法
    floatAdd(arg1, arg2) {
      var r1, r2, m, n;
      try {
        if (arg1 == null) { arg1 = 0 }
        r1 = arg1.toString().split(".")[1].length
      } catch (e) {
        r1 = 0
      }
      try {
        if (arg2 == null) { arg2 = 0 }
        r2 = arg2.toString().split(".")[1].length
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      n = (r1 >= r2) ? r1 : r2;
      return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },
    // 浮點數乘法
    floatMul(arg1, arg2) {
      var m = 0;
      if (arg1 == null) { arg1 = 0 }
      if (arg2 == null) { arg2 = 0 }
      var s1 = arg1.toString();
      var s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) { }
      try {
        m += s2.split(".")[1].length;
      } catch (e) { }

      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    // 浮點數減法
    floatSub(arg1, arg2) {
      var re1, re2, m, n;
      try {
        if (arg1 == null) { arg1 = 0 }
        re1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        re1 = 0;
      }
      try {
        if (arg2 == null) { arg2 = 0 }
        re2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        re2 = 0;
      }
      m = Math.pow(10, Math.max(re1, re2));
      n = (re1 >= re2) ? re1 : re2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }
  }
}

let compareData = {
  "riskTypeAdd": ["ROLE_ADMINISTRATIVE"],
  "riskTypeExport": ["ROLE_ADMINISTRATIVE", "ROLE_DEPUTY_CHIEF_AUDITOR", "ROLE_CHIEF_AUDITOR"],
  "riskTypeDelete": ["ROLE_ADMINISTRATIVE"],
  "riskTypeCancel": ["ROLE_CHIEF_AUDITOR"],
  "riskTypeByIdApproval": ["ROLE_ADMINISTRATIVE"],
  "riskTypeByIdEdit": ["ROLE_ADMINISTRATIVE"],
  "unitFieldAdd": ["ROLE_ADMINISTRATIVE"],
  "unitFieldDelete": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "unitFieldByIdAddFactor": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "unitFieldByIdStatus": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "unitFieldByIdGroupStatus": ["ROLE_LEADER"],
  "unitFieldByIdExport": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "unitFieldByIdEdit": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "allocationAdd": ["ROLE_ADMINISTRATIVE"],
  "allocationDelete": ["ROLE_ADMINISTRATIVE"],
  "allocationByIdStatus": ["ROLE_ADMINISTRATIVE"],
  "allocationByIdEdit": ["ROLE_ADMINISTRATIVE"],
  "riskEvaluationAdd": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "riskEvaluationDelete": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "riskEvaluationByIdEdit": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "riskEvaluationByIdStatus": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "riskEvaluationByIdGroupStatus": ["ROLE_LEADER"],  
  "riskEvaluationByIdImport": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanAdd": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanDelete": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanByIdStatus": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanByIdImport": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanByIdEdit": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "usersExport": ["ROLE_ADMINISTRATIVE", "ROLE_DEPUTY_CHIEF_AUDITOR"],
  "usersEdit": ["ROLE_ADMINISTRATIVE", "ROLE_DEPUTY_CHIEF_AUDITOR"],
  "unitMaintainGroup": ["ROLE_ADMINISTRATIVE", "ROLE_DEPUTY_CHIEF_AUDITOR"],
  "usersEdit": ["ROLE_ADMINISTRATIVE", "ROLE_DEPUTY_CHIEF_AUDITOR"],
  "auditPlanByIdStatus": ["ROLE_ADMINISTRATIVE", "ROLE_AUDITOR", "ROLE_LEADER"],
  "auditPlanByIdGroupStatus": ["ROLE_LEADER"],

}
export const checkRolesMixin = {
  methods: {
    rolesCheck: function (key) {

      let userRoles = localStorage.getItem("roles").split(',')
      if(["", undefined, null].includes(userRoles)){
        return false
      }      
      let filteredArray = userRoles.filter(value => { return compareData[key].includes(value)})
      if(filteredArray.length != 0){
        return true
      }
      return false
    }
  },
}

//判斷是否為簽核頁面
export const fromSignOffMixin = {
  methods: {
    isFromSignOff() {
      // console.log(this.$route.params?.from);
      if (this.$route.params?.from == "signOff") {
        return true;
      } else {
        return false;
      }
    },
  }
}