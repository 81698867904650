<template>
  <!-- 簽核軌跡 -->
  <material-dialog title="簽核軌跡" v-model="dialog" max-width="400">
    <div class="d-flex">
      <span class="grey--text mr-2 mb-10">版本號</span>
      {{ historyData.year }} - {{ versionText(historyData.no) }}
    </div>
    <div class="timeLine">
      <v-timeline dense>
        <v-timeline-item
          :color="statusData[idx].statusColor"
          small
          v-for="(h, idx) in historyData.history"
          :key="h.id"
        >
          <p class="mb-1">{{ h.updatedAt }}</p>
          <p class="mb-1">
            {{ h.role }}-{{ h.name }}
            <span
              class="ml-2"
              :style="`color:${statusData[idx].statusColor}`"
              >{{ statusData[idx].statusText }}</span
            >
          </p>
          <p v-show="h.desc">駁回原因: {{ h.desc }}</p>
        </v-timeline-item>
      </v-timeline>
    </div>

    <template #actions>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" outlined color="grey"> 關閉 </v-btn>
    </template>
  </material-dialog>
</template>
<script>
import { dateFormateMixin, versionMixin } from "../helper";
export default {  
  name: "DialogHistory",
  mixins: [dateFormateMixin, versionMixin],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    historyData: {
      type: Object,
      default: () => {
        return {
          year: "0000",
          No: "0",
          history: [
            {
              role: "",
              name: "",
              status: "",
              desc: "",
              updatedAt: "",
            },
          ],
        };
      },
    },
  },
  computed: {
    statusData() {
      const s = this.historyData.history.map((el) => {
        let obj = {};
        switch (el.status) {
          case 0:
            obj.statusText = "送簽";
            obj.statusColor = "#7A7A7A";
            break;
          case 1:
            obj.statusText = "核可";
            obj.statusColor = "#1867C0";
            break;
          case 2:
            obj.statusText = "駁回";
            obj.statusColor = "#CF2525";
            break;
          default:
            obj.statusText = "";
            obj.statusColor = "";
        }
        return obj;
      });
      return s;
    },
  },
};
</script>
<style lang="scss">
.timeLine {
  max-height: 400px;
  overflow-y: auto;
}
</style>
