<template>
  <v-menu
    ref="menu"
    content-class="menuu"
    v-model="menu"
    v-on="$listeners"
    :close-on-content-click="false"
    :close-on-click="false"
    transition="scale-transition"
    offset-y
    max-width="290"
    min-width="auto"
    range
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="$attrs.date"
        class="filterTimeInput"
        readonly
        dense
        outlined
        hide-details
        :label="$attrs.label"
        single-line
        v-bind="attrs"
        v-on="on"
        :append-icon="'mdi-calendar-blank'"
        clearable
        @click:clear="
          $emit('dateCange', []);
          $emit('update');
        "
      ></v-text-field>
    </template>
    <v-date-picker
      :value="$attrs.date"
      :range="$attrs.range"
      no-title
      @input="$listeners.dateCange"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn
        text
        color="primary"
        @click="
          $refs.menu.save($attrs.date);
          $emit('update');
        "
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: "MaterialDatePicker",

  data() {
    return {
      menu: null,
    };
  },
  methods: {},
  mounted() {
    // console.log(this.$attrs);
    // console.log(this.$listeners);
    // console.log(this.$children);
  },
};
</script>
<style scoped lang="scss">
.menuu {
  left: initial !important;
  right: 24px !important;
}
.filterTimeInput {
  min-width: 290px;
}
</style>
<!--
<v-menu
ref="menu2"
v-model="menu2"
:close-on-content-click="false"
:close-on-click="false"
:return-value.sync="endDate"
transition="scale-transition"
offset-y
max-width="290px"
min-width="auto"
>
<template v-slot:activator="{ on, attrs }">
  <v-text-field
    v-model="endDate"
    readonly
    class="filterTimeInput"
    outlined
    hide-details
    label="結束時間"
    single-line
    v-bind="attrs"
    v-on="on"
    @click="annual = 3"
  ></v-text-field>
</template>
<v-date-picker v-model="endDate" :allowed-dates="getAllowedDates" no-title>
  <v-spacer></v-spacer>
  <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>
  <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
    OK
  </v-btn>
</v-date-picker>
</v-menu>
-->
