<template>
  <v-alert text color="error" class="mt-4">
    <div class="d-flex mb-3">
      <v-icon color="error" size="26" class="mr-2">mdi-alert</v-icon>
      <span class="text-h4 font-weight-bold">警告</span>
    </div>
    <p class="grey1--text mt-1" style="line-height: 1.5">
      <slot />
    </p>
  </v-alert>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss"></style>
