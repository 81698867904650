<template>
  <div
    class="d-flex align-center justify-space-around"
    :class="parseAttrs('margin') ? $attrs.margin : 'ma-10'"
  >
    <div class="d-flex align-center justify-end">
      顯示
      <v-select
        class="mx-2 nowPage elevation-0"
        v-model="perItem"
        solo
        flat
        dense
        :items="PagePerItems"
        hide-details
        @change="
          page = 1;
          searchData();
        "
        hide-icon
      ></v-select>
      筆
    </div>
    <v-pagination
      v-model="page"
      :length="totalPages"
      @input="searchData"
      :total-visible="10"
    ></v-pagination>
    <div class="d-flex align-center">
      第
      <v-autocomplete
        class="mx-2 nowPage"
        v-model="toPage"
        :items="pageList"
        @change="turnPage(toPage)"
        solo
        flat
        dense
        hide-details
      ></v-autocomplete>
      頁
    </div>
  </div>
</template>
<script>
import { attrsMixin } from "../helper";
export default {
  name: "MaterialPagination",
  mixins: [attrsMixin],
  props: {
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      perItem: 10,
      PagePerItems: [1, 2, 10, 20, 50, 100],
      toPage: null,
      page: 1,
    };
  },
  computed: {
    pageList() {
      let arr = [];
      for (let i = 1; i <= this.totalPages; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  methods: {
    searchData() {
      let searchParams = {
        page: this.page,
        itemsPerPage: this.perItem,
      };
      this.$emit("pageFilter", searchParams);
    },
    turnPage(page) {
      let newPage = +page;
      if (newPage > this.totalPages || newPage < 1) {
        this.toPage = 1;
        this.page = 1;
      } else if (!!newPage) this.page = newPage;
      else return;
      this.searchData();
    },
  },
};
</script>
<style scoped>
.page-selecet {
  max-width: 25%;
}
</style>
<style lang="scss">
.nowPage {
  border-bottom: 1px solid #ccc;
  max-width: 25%;
  min-width: 83px;
}
.nowPage input {
  text-align: center;
}
</style>
