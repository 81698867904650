import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins'
import { sync } from 'vuex-router-sync'
import Datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

Vue.use(Datepicker)
Vue.config.productionTip = false

sync(store, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

