<template>
  <!-- <div> -->
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    class="elevation-1 material-table"
    hide-default-footer
    :class="$attrs['data-class']"
  >
    <slot />
    <template v-for="slot in slots" #[parseSlot(slot)]="{ item }">
      <slot :name="slot" :item="item" />
    </template>
  </v-data-table>
  <!-- </div> -->
</template>
<script>
export default {
  name: "MaterialTable",
  data() {
    return {
      slots: [
        `status`,
        `actions`,
        `updatedAt`,
        `versioningAt`,
        `createdAt`,
        `audits`,
        `factor`,
        `humanType`,
        `refItem`,
        `flow`,
        `review`,
        `fieldVer`,
        `allocationVer`,

        // -- riskEValuation --
        `inherentRisk`,
        `controlMeasures`,
        `residualRisk`,
        `cmLevel`,
        `nextAuditYear`,
        `period`,
        // -- auditPlan --
        `riskEvaluationVersion`,
        `scheduleYear`,
        `manDay`,
        `notes`,
        // -- riskType --
        `no`,
        // -- unitField --
        `groupId`,
        `enabled`,

        // -- unitField --
        `auditor`,
        // settings
        `roles`,
      ],
    };
  },
  computed: {
    // slotList() {
    //   console.log(this.$attrs);
    //   console.log(this.$attrs.headers);
    //   let arr = [];
    //   this.$attrs.headers.forEach((el) => {
    //     arr.push(el.value);
    //   });
    //   console.log({ arr });
    //   return arr;
    // },
  },
  methods: {
    parseSlot(slot) {
      return `item.${slot}`;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
// .material-table {
//   &:deep(.v-data-table-header) {
//     background-color: #00a29d;
//     th {
//       color: #fff !important;
//       white-space: nowrap;
//       font-size: 14px;
//       height: 44px;
//     }
//     i {
//       color: #fff !important;
//     }
//   }
//   &:deep(.v-data-table__wrapper) {
//     td {
//       height: 44px;
//       font-size: 13px;
//     }
//   }
// }
// .material-table:not(.noTrColor) {
//   &:deep(.v-data-table__wrapper) {
//     tbody tr:nth-child(even) {
//       background-color: #f6fcfc;
//     }
//   }
// }
</style>
