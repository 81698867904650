import axios from "axios";

const instance = axios.create({
    baseURL: process.env.VUE_APP_HOST_URL,
    withCredentials: true,
    // xsrfCookieName: "csrftoken",
    // xsrfHeaderName: "X-CSRFToken",
});
instance.interceptors.request.use(
    (config) => {

        return config;
    },
    (err) => {

        return Promise.reject(err);
    }
);
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        console.log("err", err);
        return Promise.reject(err)
    }
);

export default function (method, url, data = null, config) {
    method = method.toLowerCase();
    switch (method) {
        case "get":
            return instance.get(url, { params: data });
        case "post":
            return instance.post(url, data, config);
        case "patch":
            return instance.patch(url, data);
        case "delete":
            return instance.delete(url, { params: data });
        case "patch":
            return instance.patch(url, data);
        default:
            console.log(`未知的method : ${method}`);
            return false;
    }
}