<template>
  <!-- <v-chip class="mr-1" color="grey1" dark small><slot /></v-chip> -->
  <v-btn v-on="$listeners" depressed class="cancelBtn" outlined><slot /></v-btn>
</template>
<script>
export default {
  name: "BtnCancel",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.cancelBtn {
  background-color: #fff;
  border-color: $grey3;
  color: $grey2;
}
</style>
