<template>
  <span>
    <v-chip
      v-bind="$attrs"
      v-if="status != 4"
      :color="statusData.color"
      small
      dark
      >{{ statusData.text }}</v-chip
    >
    <v-chip v-bind="$attrs" v-else :color="statusData.color" small>{{
      statusData.text
    }}</v-chip>
  </span>
</template>
<script>
export default {
  name: "ChipStatus",
  props: {
    status: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    statusData() {
      switch (this.status) {
        case 0:
          return { text: "草稿", color: "#4CAF50" };
        case 1:
          return { text: "初稿", color: "#804F05" };
        case 2:
          return { text: "待審", color: "#E9843B" };
        case 3:
          return { text: "定版", color: "primary" };
        case 4:
          return { text: "取消", color: "#E9E9E9" };
      }
    },
  },
};
</script>
