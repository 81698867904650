// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: false,
  drawerImage: true,
  mini: true,
  // == Drawer List ==
  items: [
    {
      title: '首頁',
      icon: 'mdi-home',
      to: '/home/to-sign-off',
    },
    // {
    //   title: '總體風險概覽',
    //   icon: 'mdi-book-open',
    //   to: '/risk-overview',
    // },
    // {
    //   title: '稽核領域管理',
    //   icon: 'mdi-view-dashboard',
    //   to: '/audit-field/risk-type',
    // },
    {
      items: {
        title: '稽核領域管理',
        icon: 'mdi-view-dashboard',
        type: 'group',
        active: false,
        items: [
          {
            title: '風險類型與項目',
            to: '/audit-field/risk-type',
          },
          {
            title: '單位稽核領域',
            to: '/audit-field/unit-field',
          },
        ]
      }
    },
    {
      items: {
        title: '風險評估管理',
        icon: 'mdi-calculator-variant',
        type: 'group',
        active: false,
        items: [
          {
            title: '風險評估',
            // icon: 'mdi-bell',
            to: '/risk-evaluation/management',
          },
          {
            title: '配分管理',
            // icon: 'mdi-bell',
            to: '/allocation/management',
          },
        ]
      }
    },
    {
      title: '稽核計畫管理',
      icon: 'mdi-dns',
      to: '/audit-plan',
    },
    // {
    //   items: {
    //     title: '稽核計畫管理',
    //     icon: 'mdi-dns',
    //     type: 'group',
    //     items: [
    //       {
    //         title: "稽核計畫",
    //         to: '/audit-plan'
    //       },
    //       {
    //         title: "人力統計",
    //         to: '/man-day'
    //       }
    //     ],
    //   }

    // },
    {
      items: {
        title: '系統設定',
        icon: 'mdi-cog',
        active: false,
        items: [
          {
            title: '帳號管理',
            // icon: 'mdi-bell',
            to: '/accounts',
          },
          {
            title: '單位維護',
            // icon: 'mdi-bell',
            to: '/unit-maintain',
          },
          {
            title: '系統日誌',
            // icon: 'mdi-bell',
            to: '/audit-log',
          },
        ]
      }
    }

  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

// {
//   title: 'About',
//   icon: 'mdi-telescope',
//   to: '/about',
// },
// {
//   title: 'Dashboard',
//   icon: 'mdi-view-dashboard',
//   to: '/dashboard',
// },
// {
//   title: 'User Profile',
//   icon: 'mdi-account',
//   to: '/profile',
// },
// {
//   title: 'Regular Tables',
//   icon: 'mdi-clipboard-outline',
//   to: '/regularTables',
// },
// {
//   title: 'Icons',
//   icon: 'mdi-chart-bubble',
//   to: '/components/icons',
// },
// {
//   title: 'Google Maps',
//   icon: 'mdi-map-marker',
//   to: '/maps/google',
// },
// {
//   title: 'Notifications',
//   icon: 'mdi-bell',
//   to: '/components/notifications',
// },