<template>
  <div class="d-flex justify-space-between align-center">
    <h1 class="text-h1 text-no-wrap">{{ $attrs.title }}</h1>
    <span class="text-no-wrap text-body-2">版本</span>
    <chip-grey class="mr-1"
      >{{ versionData.year }}-{{ versionText(versionData.no) }}</chip-grey
    >
    <!-- TODO:單位 -->
    <span v-if="versionData.group != undefined" class="text-no-wrap text-body-2"
      >單位</span
    >
    <chip-grey v-if="versionData.group != undefined" class="mr-1">{{
      versionData.group
    }}</chip-grey>

    <span class="text-no-wrap text-body-2">狀態</span>
    <chip-status :status="versionData.status"></chip-status>
  </div>
</template>
<script>
import { versionMixin } from "../helper";
export default {
  name: "VersionStatusTitle",
  mixins: [versionMixin],
  props: {
    versionData: {
      type: Object,
      default: () => {
        return { year: "0000", no: "00", status: 0 };
      },
    },
  },
};
</script>
