import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const theme = {
  primary: '#1867C0',
  secondary: '#00B1DB',
  // accent: '#e91e63',
  accent: '#00a29d',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#CF2525',
  fubonBlue: '#0095B8',
  fubonGreen: '#00A29D',
  orange: '#E9843B',
  grey1: '#444444',
  grey2: '#7A7A7A',
  grey3: '#CACACA',
  grey4: '#E8E8E8',
}

export default new Vuetify({
  customVariables: ["~/styles/variables.scss"],
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
