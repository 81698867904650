import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/home',
  //   redirect: '/'
  // },
  {
    path: '/',
    component: () => import('@/layouts'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home'),
        alias: '/home',
        children: [
          {
            path: 'to-sign-off',
            name: 'ToSignOff',
            component: () => import('../views/home/ToSignOff.vue')
          },
          {
            path: 'to-sign-off/:type/:year/:no',
            name: 'ToSignOffById',
            component: () => import('../views/home/ToSignOffSetting.vue')
          },
          {
            path: 'to-do',
            name: 'ToDo',
            component: () => import('../views/home/ToDo.vue')
          },

        ]
      },
      {
        path: 'sign-off-browse',
        name: 'SignOffBrowseLayout',
        component: () => import('../views/home/SignOffBrowse.vue'),
        children: [
          {
            path: ':type/unit-field/:id/:from?',
            name: 'UnitFieldSettingSignOff',
            component: () => import('../views/auditField/UnitFieldSetting.vue'),
          },
          {
            path: ':type/risk-evaluation/:id/:from?',
            name: 'RiskValuationSettingSignOff',
            component: () => import('../views/risk/RiskValuationSettings.vue'),
          },
          {
            path: ':type/audit-plan/:id/:from?',
            name: 'AuditPlanSettingSignOff',
            component: () => import('../views/auditPlan/AuditPlanSetting.vue'),
          },
          {
            path: ':type/allocation/:id/:from?',
            name: 'AllocationSettingSignOff',
            component: () => import('../views/risk/AllocationSetting.vue'),
          },
          {
            path: ':type/risk-type/:id/:from?',
            name: 'RiskTypeSettingSignOff',
            component: () => import('../views/auditField/RiskTypeSetting.vue')
          },
          // {
          //   path: 'to-sign-off/:type/:year/:no',
          //   name: 'ToSignOffById',
          //   component: () => import('../views/home/ToSignOffSetting.vue')
          // },
          // {
          //   path: 'to-do',
          //   name: 'ToDo',
          //   component: () => import('../views/home/ToDo.vue')
          // },

        ]
      },
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/about'),
        children: [
          {
            path: 'child1',
            component: () => import('../views/about/AboutChild1.vue')
          },
          {
            path: 'child2',
            component: () => import('../views/about/AboutChild2.vue')
          },
        ]
      },
      // 總體風險概覽
      {
        path: 'risk-overview',
        name: 'RiskOverview',
        component: () => import('../views/riskOverview'),
      },
      // 稽核領域管理
      {
        path: 'audit-field',
        name: 'AuditField',
        component: () => import('../views/auditField'),
        children: [
          {
            path: 'risk-type',
            name: 'RiskTypeTable',
            component: () => import('../views/auditField/RiskTypeTable.vue')
          },
          {
            path: 'risk-type/:id',
            name: 'RiskTypeSetting',
            component: () => import('../views/auditField/RiskTypeSetting.vue')
          },
          {
            path: 'unit-field',
            name: 'UnitFieldTable',
            component: () => import('../views/auditField/UnitFieldTable.vue')
          },
          {
            path: 'unit-field/:id',
            name: 'UnitFieldSetting',
            component: () => import('../views/auditField/UnitFieldSetting.vue'),
            // props: (route) => route.params
          }
        ]
      },
      // 風險評估管理--風險評估
      {
        path: 'risk-evaluation',
        name: 'RiskEvaluation',
        component: () => import('../views/risk/RiskEvaluation.vue'),
        children: [
          {
            path: 'management',
            name: 'RiskEvaluationManagement',
            component: () => import('../views/risk/RiskEvaluationManagement.vue'),
          },
          {
            path: 'setting/:id',
            name: 'RiskValuationSetting',
            component: () => import('../views/risk/RiskValuationSettings.vue'),
          }
        ]

      },
      // 風險評估管理--配分管理
      {
        path: 'allocation',
        name: 'Allocation',
        component: () => import('../views/risk/Allocation.vue'),
        children: [
          {
            path: 'management',
            name: 'AllocationManagement',
            component: () => import('../views/risk/AllocationManagement.vue'),
          },
          {
            path: 'setting/:id',
            name: 'AllocationSetting',
            component: () => import('../views/risk/AllocationSetting.vue'),
          }
        ]
      },
      // 稽核計劃管理--稽核計畫
      {
        path: 'audit-plan',
        name: 'AuditPlan',
        component: () => import('../views/auditPlan'),
      },
      {
        path: 'audit-plan/:id',
        name: 'AuditPlanSetting',
        component: () => import('../views/auditPlan/AuditPlanSetting.vue'),
      },
      // 系統設定
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('../views/system/Accounts.vue'),
      },
      {
        path: 'unit-maintain',
        name: 'UnitMaintain',
        component: () => import('../views/system/UnitMaintain.vue'),
      },
      {
        path: 'audit-log',
        name: 'AuditLog',
        component: () => import('../views/system/AuditLog.vue'),
      },
      // Dev
      {
        path: 'ui-unit',
        name: 'UiUnit',
        component: () => import('../views/dev/UiUnit.vue'),
      },


      // {
      //   path: 'regularTables',
      //   name: 'RegularTables',
      //   component: () => import('@/views/RegularTables.vue'),
      // },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  // 404 (放最後)
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes,
})

export default router
