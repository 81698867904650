import req from "./api"
export const apiGetGroups = () => req("get", "/group")
export const apiSaveGroups = (data) => req("post", "/group/", data)
export const apiGetUsers = (data) => req("get", "/users", data)
export const apiGetUsersById = (id) => req("get", "/users/"+id)
export const apiSaveUsersById = (id, data) => req("patch", "/users/"+id, data)

// 取得角色列表
export const apiGetRoles = (data) => req("get", "/roles", data)

export const apiGetExport = () => req("get", "/export")

// 系統日誌
export const apiGetAudit = (data) => req("get", "/audit", data)

// 單位維護
export const apiGetUnit = (data) => req("get", "/unit", data)
export const apiEditUnit = (id, data) => req("post", "/unit/"+id, data)

// 群組管理
export const apiSaveGroup = (data) => req("post", "/group", data)
export const apiGetGroup = () => req("get", "/group")
export const apiDeleteGroup = (id) => req("delete", "/group/"+id)
