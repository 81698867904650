<template>
  <material-dialog
    v-model="alert.open"
    :title="alert.title || '提示'"
    max-width="420"
  >
    <p v-html="alert.content"></p>
    <!-- <template #actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="alert.open = false">我知道了</v-btn>
    </template> -->
    <template #actions>
      <div v-if="alert.type === 'leaveAlert'" class="d-flex justify-end w-100">
        <v-spacer></v-spacer>
        <btn-cancel class="mr-2" @click="onCancel">取消</btn-cancel>
        <v-btn color="error" @click="onOk">確定</v-btn>
      </div>
      <div
        v-if="alert.type === 'approveAlert'"
        class="d-flex justify-end w-100"
      >
        <v-spacer></v-spacer>
        <btn-cancel class="mr-2" @click="onCancel">取消</btn-cancel>
        <v-btn color="primary" depressed @click="onOk">繼續</v-btn>
      </div>
      <div v-if="!alert.type" class="d-flex justify-end w-100">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="alert.open = false">我知道了</v-btn>
      </div>
    </template>
  </material-dialog>
</template>
<script>
import { sync } from "vuex-pathify";
export default {
  name: "MaterialAlert",
  computed: {
    ...sync("global", ["alert"]),
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.alert.onCancel();
      this.alert.open = false;
    },
    onOk() {
      this.alert.onOk();
      this.alert.open = false;
    },
  },
};
</script>
