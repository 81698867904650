<template>
  <!-- <div> -->
  <v-card
    class="filter"
    :class="isExpand ? 'active' : ''"
    v-bind="$attrs"
    width="280"
    :style="{ height: `${filterHeight}` }"
  >
    <v-card-title>
      <span class="text-h5 mr-auto">篩選條件</span>
      <v-btn
        small
        style="min-width: 44px"
        depressed
        color="primary"
        @click="filterChoose"
        >篩選</v-btn
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <!-- 狀態 -->
      <div class="text-h5 filterItemTitle">
        狀態

        <span class="primary--text" @click="resetfilterItem('status')"
          >清除</span
        >
      </div>
      <v-chip-group
        v-model="status"
        class="d-flex"
        active-class="white--text primary"
        column
      >
        <v-chip
          small
          class="mr-1 mt-2"
          v-for="sItem in statusList"
          :key="`sItem-${sItem.value}`"
          :value="sItem.value"
        >
          {{ sItem.text }}
        </v-chip>
      </v-chip-group>
      <!-- @click="toggleSItem(sItem)" -->
      <!-- 年度 -->
      <div class="text-h5 filterItemTitle mt-5">
        年度
        <span class="primary--text" @click="resetfilterItem('annual')"
          >清除</span
        >
      </div>
      <v-chip-group
        v-model="annual"
        class="d-flex"
        active-class="white--text primary"
        column
      >
        <v-chip
          small
          class="mr-1 mt-2"
          v-for="aItem in annualList"
          :key="`aItem-${aItem.value}`"
          :color="aItem.active ? 'primary' : ''"
        >
          {{ aItem.text }}
        </v-chip>
      </v-chip-group>
      <!-- <v-chip class="mr-1 mt-1" small> 自訂區間 </v-chip> -->
      <div
        class="d-flex justify-space-between align-center mt-2"
        style="width: 90%"
      >
        <v-select
          v-model="startDate"
          class="filterTimeInput filterInput"
          outlined
          hide-details
          label="起始"
          single-line
          dense
          :items="startYearItems"
          @click="annual = 2"
          :menu-props="{ offsetY: true }"
        ></v-select>
        -
        <v-select
          v-model="endDate"
          class="filterTimeInput filterInput"
          outlined
          hide-details
          label="結束"
          single-line
          dense
          :items="endYearItems"
          @click="annual = 2"
          :menu-props="{ offsetY: true }"
        ></v-select>
      </div>

      <!-- 單位 -->
      <div v-show="$attrs.showUnit">
        <div class="text-h5 filterItemTitle mt-5 mb-2">
          單位
          <span class="primary--text" @click="resetfilterItem('unit')"
            >清除</span
          >
        </div>
        <v-select
          v-model="unit"
          :items="unitList"
          item-value="name"
          item-text="name"
          append-icon="mdi-menu-down"
          label="選擇單位"
          class="filterInput"
          dense
          single-line
          outlined
          :menu-props="{ offsetY: true }"
          hide-details
          @change="unitChoose()"
        >
        </v-select>
        <v-chip
          small
          class="mr-1 mt-2"
          v-for="uItem in unitFilter"
          :key="`uItem-${uItem}`"
          color="primary"
        >
          {{ uItem }}
          <!-- {{ unitTrans[uItem] }} -->
        </v-chip>
      </div>

      <!-- 編輯人 -->
      <div v-show="parseAttrs('showEditor')">
        <div class="subtitle-1 mt-5">編輯人</div>
        <v-select
          v-model="editor"
          :items="editorList"
          item-value="value"
          item-text="text"
          append-icon="mdi-menu-down"
          placeholder="選擇人員"
          dense
          outlined
          @change="editChoose()"
        >
        </v-select>
        <v-chip
          small
          class="mr-1 mt-2"
          v-for="eItem in editorFilter"
          :key="`eItem-${eItem}`"
          color="primary"
        >
          {{ editorTrans[eItem] }}
        </v-chip>
      </div>
    </v-card-text>
  </v-card>
  <!-- </div> -->
</template>
<script>
import moment from "moment";
import { apiGetGroups } from "../api/settings";
export default {
  name: "MaterialFilter",
  props: {
    isExpand: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterHeight: `${innerHeight - 250}px`,
      status: null,
      annual: null,
      menu1: false,
      menu2: false,
      startYearItems: [],
      endYearItems: [],
      startDate: null,
      endDate: null,
      statusList: [
        { active: false, text: "草稿", value: 0 },
        // { active: false, text: "初稿", value: 1 },
        { active: false, text: "待審", value: 2 },
        { active: false, text: "定版", value: 3 },
        { active: false, text: "取消", value: 4 },
      ],
      annualList: [
        { active: false, text: "今年度", value: 1 },
        { active: false, text: "去年度", value: 2 },
        { active: false, text: "自訂年度區間", value: 3 },
      ],
      unit: null,
      unitList: [
        // { text: "客戶服務", value: 1 },
        // { text: "通路推展部", value: 2 },
      ],
      unitFilter: [],
      unitTrans: {},
      editor: null,
      editorList: [
        { text: "傅家仁", value: 1 },
        { text: "何健偉", value: 2 },
      ],
      editorFilter: [],
      editorTrans: {},
    };
  },
  watch: {
    annual(val) {
      if (val != 3) {
        this.startDate = null;
        this.endDate = null;
      }
    },
    startDate(val) {
      // if (moment(val) > moment(this.endDate)) {
      //   this.endDate = null;
      // }
      this.setEndYearRange(val);
    },
  },
  mounted() {
    this.unitTrans = this.produceTrans(this.unitList);
    this.editorTrans = this.produceTrans(this.editorList);
    this.setStartYearRange();
    this.getGroups();
  },
  methods: {
    parseAttrs(attr) {
      return Object.keys(this.$attrs).includes(attr);
    },
    getAllowedDates(val) {
      return moment(val) > moment(this.startDate);
    },
    filterChoose() {
      let thisYear = new Date().getFullYear();
      let filter_data = {
        status: this.status,
        // annual: this.annual,
        start: this.startDate,
        end: this.endDate,
      };
      if (this.annual == 0) {
        filter_data.start = thisYear;
        filter_data.end = thisYear;
      } else if (this.annual == 1) {
        filter_data.start = thisYear - 1;
        filter_data.end = thisYear - 1;
      }
      if (this.unitFilter.length != 0) {
        console.log(this.unitFilter);
        filter_data.groups = this.unitFilter;
      }
      this.$emit("filter", filter_data);
    },
    unitChoose() {
      if (!this.unitFilter.includes(this.unit)) {
        this.unitFilter.push(this.unit);
      }
    },
    editChoose() {
      if (!this.editorFilter.includes(this.editor)) {
        this.editorFilter.push(this.editor);
      }
    },
    produceTrans(transList) {
      let transObject = {};
      for (let item of transList) {
        transObject[item.value] = item.text;
      }
      return transObject;
    },
    resetfilterItem(item) {
      switch (item) {
        case "status":
          this.status = null;
          break;
        case "annual":
          this.annual = null;
          this.startDate = null;
          this.endDate = null;
          break;
        case "unit":
          this.unit = null;
          this.unitFilter = [];
      }
    },
    //year range
    setStartYearRange() {
      let nowY = new Date().getFullYear();
      // console.log({ nowY });
      for (let i = +nowY - 10; i <= +nowY + 10; i++) {
        this.startYearItems.push(i);
      }
      // console.log(this.startYearItems);
    },
    setEndYearRange(startY) {
      this.endYearItems = [];
      let nowY = new Date().getFullYear();
      console.log({ nowY });
      console.log({ startY });
      for (let i = +startY; i <= +nowY + 10; i++) {
        this.endYearItems.push(i);
      }
      // console.log(this.endYearItems);
    },
    async getGroups() {
      try {
        let res = await apiGetGroups();
        console.log(this.unitList);
        this.unitList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.filter {
  position: absolute;
  z-index: 1;
  left: -296px;
  transition: left 0.5s ease-in-out;
  min-height: 500px;
  height: 100%;
  .filterItemTitle {
    color: #7a7a7a;
    display: flex;
    align-items: baseline;

    span {
      cursor: pointer;
      margin-left: auto;
      font-size: 13px;
    }
  }
  &.active {
    left: 16px;
  }
}
.filterInput {
  &:deep(.v-label) {
    font-size: 12px;
  }
}
.filterTimeInput {
  max-width: 100px;
  min-width: 60px;
  // &:deep(.v-input__slot) {
  //   min-height: 40px;
  //   padding-right: 0 !important;
  //   .v-text-field__slot {
  //     margin-left: 8px;
  //   }
  //   .v-input__append-inner {
  //     margin: 10px 0;
  //   }
  // }
  // &:deep(.v-label) {
  // line-height: 40px;
  // top: 20%;
  // left: 4px !important;
  // }
}
</style>

<!--時間區間 
<div class="d-flex justify-space-between align-center mt-2">
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    :return-value.sync="startDate"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="startDate"
        readonly
        class="filterTimeInput"
        outlined
        hide-details
        label="起始年度"
        single-line
        v-bind="attrs"
        v-on="on"
        @click="annual = 3"
      ></v-text-field>
    </template>

    <v-date-picker v-model="startDate" no-title>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu1.save(startDate)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
  ~
  <v-menu
    ref="menu2"
    v-model="menu2"
    :close-on-content-click="false"
    :close-on-click="false"
    :return-value.sync="endDate"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        readonly
        class="filterTimeInput"
        outlined
        hide-details
        label="結束年度"
        single-line
        v-bind="attrs"
        v-on="on"
        @click="annual = 3"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="endDate"
      :allowed-dates="getAllowedDates"
      no-title
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</div>
-->
